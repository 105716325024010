import { Button, Form, Input } from "antd";
import React from "react";
import "./style.css";
import { IoMdLogIn } from "react-icons/io";
import { useNavigate } from "react-router-dom";
import instance from "../../api/axiosConfig";
import axios from "../../api/axiosConfig";

const Login = () => {
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const onFinish = async (values) => {
    const res = await axios.post(`/user/signin`, values);
    if (res.status == 200) {
      localStorage.setItem("user", JSON.stringify(values));
      navigate("/event");
    }
  };
  return (
    <div className="login-main">
      <div className="login-card">
        <div className="icon">
          <IoMdLogIn size={28} color="blue" />
          <span>Login</span>
        </div>
        <div className="form">
          <Form form={form} onFinish={onFinish}>
            <Form.Item
              name="email"
              rules={[{ required: true, message: "Required" }]}
            >
              <Input placeholder="Enter Email" />
            </Form.Item>
            <Form.Item
              name="password"
              rules={[{ required: true, message: "Required" }]}
            >
              <Input.Password placeholder="Enter Password" />
            </Form.Item>
            <div style={{ textAlign: "center" }}>
              <Button type="primary" htmlType="submit">
                Invia
              </Button>
            </div>
          </Form>
        </div>
      </div>
    </div>
  );
};

export default Login;
