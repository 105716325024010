import React, { lazy } from "react";
import { Route, Routes } from "react-router-dom";
import Loader from "../components/loader/index";

const EventCards = lazy(() => import("../pages/event/eventCards"));
const EventPage = lazy(() => import("../pages/event/eventTable"));

const AdminRoutes = () => {
  return (
    <React.Suspense fallback={<Loader />}>
      <Routes>
        <Route path="/*" element={<EventCards />} />
        <Route path="/:id" element={<EventPage />} />
      </Routes>
    </React.Suspense>
  );
};

export default AdminRoutes;
