import React, { lazy } from "react";
import Login from "../components/auth/login";
import EventProfile from "../pages/event/index";
import { BrowserRouter, Route, Routes } from "react-router-dom";

import {
  ProtectedAuthRoute,
  ProtectedRoute,
  ProtectedAdminRoute,
  ProtectedUserRoute,
} from "./protectedRoute";
import Loader from "../components/loader";

const EventList = lazy(() => import("../pages/user/eventList"));
const RegisterEvent = lazy(() => import("../pages/user/registerEvent"));

const Index = () => {
  return (
    <>
      <BrowserRouter>
        <React.Suspense fallback={<Loader />}>
          <Routes>
            <Route
              path="/"
              element={
                // <ProtectedAdminRoute>
                //   <ProtectedAuthRoute>
                <Login />
                //   </ProtectedAuthRoute>
                // </ProtectedAdminRoute>
              }
            />
            <Route
              path="/event*"
              element={
                <ProtectedRoute>
                  <EventProfile />
                </ProtectedRoute>
              }
            />

            <Route
              path="/register/:_id"
              element={
                // <ProtectedUserRoute>
                //   <ProtectedAuthRoute>
                <RegisterEvent />
                //   </ProtectedAuthRoute>
                // </ProtectedUserRoute>
              }
            />
            <Route
              path="/event-list"
              element={
                // <ProtectedUserRoute>
                <ProtectedAuthRoute>
                  <EventList />
                </ProtectedAuthRoute>
                // </ProtectedUserRoute>
              }
            />
          </Routes>
        </React.Suspense>
      </BrowserRouter>
    </>
  );
};

export default Index;
