import React from "react";
import { Navigate } from "react-router-dom";

const ProtectedRoute = ({ children }) => {
  const user = localStorage.getItem("user");

  if (!user && !window.location.href.includes("admin-eventfe")) {
    return <Navigate to="/" replace />;
  }

  return children;
};

const ProtectedAdminRoute = ({ children }) => {
  const user = localStorage.getItem("user");

  if (!user && !window.location.href.includes("admin-eventfe")) {
    return <Navigate to="/event-list" replace />;
  }

  return children;
};

const ProtectedUserRoute = ({ children }) => {
  if (window.location.href.includes("admin-eventfe")) {
    return <Navigate to="/" replace />;
  }

  return children;
};

const ProtectedAuthRoute = ({ children }) => {
  const user = localStorage.getItem("user");

  if (user) {
    return <Navigate to="/event" replace />;
  }

  return children;
};

export {
  ProtectedRoute,
  ProtectedAuthRoute,
  ProtectedAdminRoute,
  ProtectedUserRoute,
};
