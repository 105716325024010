import { NavLink, useNavigate } from "react-router-dom";
import { FaBars, FaHome, FaLock, FaMoneyBill, FaUser } from "react-icons/fa";
import { MdMessage, MdOutlinePayment } from "react-icons/md";
import { BiAnalyse, BiSearch, BiSolidDashboard } from "react-icons/bi";
import { AiFillHeart, AiFillSetting } from "react-icons/ai";
import { BsCartCheck, BsPersonFillCheck } from "react-icons/bs";
import { useState, useEffect, useCallback, useRef } from "react";
import { AnimatePresence, motion } from "framer-motion";
import useWindowDimensions from "../../utils/getWindowDimenssions";

import "./style.css";

const menu_routes = [
  {
    path: "/event",
    name: "Events",
    val: "event",
    icon: <BiSolidDashboard color="white" />,
  },
  {
    name: "Logout",
    icon: <AiFillSetting color="white" />,
  },
];

const SideBar = ({ children }) => {
  const { height, width } = useWindowDimensions();
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(true);
  const [activeIndex, setActiveIndex] = useState(0);

  const [configure, setConfigure] = useState(null);
  const [hoverIndex, setHoverIndex] = useState(null);
  const [adminId, setAdminId] = useState(null);
  const [routes, setRoutes] = useState([]);
  const [textColor, setTextColor] = useState("#ffffff"); // Default text color

  const toggle = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    for (let index = 0; index < menu_routes.length; index++) {
      if (window.location.href.includes(menu_routes[index]?.val)) {
        setActiveIndex(index);
      }
    }
  }, []);

  const showAnimation = {
    hidden: {
      width: 0,
      opacity: 0,
      transition: {
        duration: 0.5,
      },
    },
    show: {
      opacity: 1,
      width: "auto",
      transition: {
        duration: 0.5,
      },
    },
  };

  return (
    <>
      <div className="main-container">
        <motion.div
          animate={{
            width: isOpen && width < 600 ? "190px" : isOpen ? "240px" : "65px",
            position: "relative",
            transition: {
              duration: 0.3,
              type: "spring",
              damping: 10,
            },
          }}
          style={{
            height: "auto",
          }}
          className={`sidebar `}
        >
          <div className="top_section">
            <AnimatePresence>
              {isOpen && (
                <motion.h1
                  variants={showAnimation}
                  initial="hidden"
                  animate="show"
                  exit="hidden"
                  className="logo"
                ></motion.h1>
              )}
            </AnimatePresence>

            <div
              className="bars"
              style={{ fontSize: "22px", cursor: "pointer" }}
            >
              <FaBars
                onClick={toggle}
                style={{
                  color: "white",
                  cursor: "pointer",
                }}
                onMouseEnter={() => setHoverIndex("toggle")}
                onMouseLeave={() => setHoverIndex(null)}
              />
            </div>
          </div>

          <section
            className="routes"
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              rowGap: "100%",
            }}
          >
            <div>
              {menu_routes.map((route, index) => {
                return (
                  <NavLink
                    to={route.path}
                    key={index}
                    className={`link menu-item ${
                      index == activeIndex ? "active-menu-item" : ""
                    }`}
                    onClick={() => {
                      if (route.name == "Logout") {
                        localStorage.removeItem("user");
                        window.location.reload();
                      }
                      setActiveIndex(index);
                    }}
                    style={{
                      background: "black",
                    }}
                    // onClick={()}
                    // activeclassname="active"
                    onMouseEnter={() => setHoverIndex(index)}
                    onMouseLeave={() => setHoverIndex(null)}
                  >
                    <div style={{ fontSize: "22px", color: "white" }}>
                      {route.icon}
                    </div>
                    <AnimatePresence>
                      {isOpen && (
                        <motion.div
                          variants={showAnimation}
                          initial="hidden"
                          animate="show"
                          exit="hidden"
                          // className="link_text"
                          style={{
                            color: "white",
                            cursor: "pointer",
                            textDecoration: "none",
                          }}
                        >
                          {route.name}
                        </motion.div>
                      )}
                    </AnimatePresence>
                  </NavLink>
                );
              })}
            </div>
          </section>
        </motion.div>

        <main
          style={{
            overflowX: "auto !important",
            margin: "7px",
            width: isOpen && width < 600 ? "60%" : isOpen ? "80%" : "95%",
          }}
          className="sidebar-main-sec"
        >
          {children}
        </main>
      </div>
    </>
  );
};

export default SideBar;
