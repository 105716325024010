import { Slide, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Routing from "./routes/Routing";

import i18next from "i18next";
import { I18nextProvider } from "react-i18next";

i18next.init({
  lng: "sp",
  resources: {
    en: {
      common: {
        Label: "Select the date:",
      },
    },
    sp: {
      common: {
        Label: "Seleziona il giorno:",
      },
    },
  },
});

function App() {
  return (
    <I18nextProvider i18n={i18next}>
      <Routing />
      <ToastContainer
        position="top-right"
        autoClose={6000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        transition={Slide}
      />
    </I18nextProvider>
  );
}

export default App;
